import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"
import * as System from "../../design-system"
import { useState } from "react"

const SecondPage = () => {
  const [loaded, setLoaded] = useState(false)
  return (
    <Layout>
      <SEO title="Test Page" />
      {!loaded && <System.Paragraph text="로딩 중 입니다.." />}
      <iframe
        src="https://kids-landing.vercel.app/"
        style={{
          width: "100%",
          height: "10000px",
        }}
        onLoad={() => setLoaded(true)}
      />
    </Layout>
  )
}

export default SecondPage
